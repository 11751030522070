import React, { useState } from "react"
import styled from "styled-components"
import startLogo from "../assets/images/logo-title.png"
import { useTransition } from "transition-hook"
import Seo from "../components/Seo"

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(55, 51, 40, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`
const StyledImage = styled.img`
  @media (max-width: 768px) {
    padding-top: 3rem;
  }
  @media (orientation: landscape) and (max-width: 915px) {
    height: 60%;
    margin-top: -10%;
  }
`

const BtnWrapper = styled.button`
  text-transform: uppercase;
  border: 3px solid #cbb26a;
  background-color: transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0.2rem;
  color: #cbb26a;
  cursor: pointer;
  margin-top: 0;
  @media (orientation: landscape) and (max-width: 915px) {
    margin-top: -2%;
  }
`

const Start = ({ isOpen, setIsOpen }) => {
  const [onOff, setOnOff] = useState(true)
  const { stage } = useTransition(onOff, 300) // (state, timeout)

  return (
    <>
      <Seo title="Logo" />
      <Wrapper
        style={{
          transition: "2s linear",
          height: "100vh",
          // opacity: stage === "enter" ? 1 : 0,
          // display: stage === "enter" ? "none" : "flex",
          top: stage === "enter" ? "0%" : "-130%",
        }}
      >
        <StyledImage
          src={startLogo}
          // onClick={() => setOnOff(!onOff)}
          alt="page-logo"
          title="Logo Higienistka Magdalena Romatowska"
        />

        <BtnWrapper
          onClick={() => {
            setOnOff(!onOff)
            setIsOpen(!isOpen)
          }}
        >
          enter
        </BtnWrapper>
      </Wrapper>
    </>
  )
}

export default Start
