import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import photo from "../assets/images/8.png"
import styled from "styled-components"
import Start from "./start"
import Typed from "react-typed"
import { FiArrowRight } from "react-icons/fi"
import Seo from "../components/Seo"
import CookieConsent from "react-cookie-consent"
import AOS from "aos"
import "aos/dist/aos.css"

const Wrapper = styled.div`
  max-height: 100vh;
  width: auto;
  max-width: 100vw;
  /* background: white; */
  /* background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(55, 51, 40, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  ); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  @media (max-width: 768px) {
    /* padding-top: 2rem; */
    flex-direction: column;
    margin-top: 3rem;
  }
  @media (orientation: landscape) and (max-width: 915px) {
    margin-top: 5%;
    flex-direction: row;
  }
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  width: 60%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledH2 = styled.h2`
  text-align: center;
  padding: 2rem 1rem;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    font-size: 1rem;
  }
`

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 1rem 1rem;
  /* color: black; */
  &:visited,
  &:active {
    /* color: black; */
    text-decoration: none;
  }
`

const StyledPhoto = styled.img`
  /* width: 100vw;
  position: absolute;
  top: 5%;
  right: -50%; */
  max-height: 100%;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 375px) {
    max-width: 70%;
  }
`

const StyledCookieLink = styled(Link)`
  padding: 0rem 0.5rem;
  color: #cbb26a;
  &:visited,
  &:active {
    color: #cbb26a;
    text-decoration: none;
  }
`

export default function Home() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])

  const [isOpen, setIsOpen] = useState(true)

  return (
    <>
      <Start isOpen={isOpen} setIsOpen={setIsOpen} />
      <Layout>
        <Seo title="Home" />
        <Wrapper style={{ height: isOpen ? "0vh" : "100vh" }}>
          <StyledHeader
            data-aos="fade-right"
            data-aos-delay="1200"
            title="Higienistka Magdalena Romatowska"
          >
            <Typed
              strings={["Cześć, tu Magda!", "Twoja higienistka!"]}
              loop={true}
              typeSpeed={70}
              backSpeed={60}
              className="typeAnimation"
            />

            <StyledH2>
              Higienistka, asystentka stomatologiczna – Absolwentka Szkoły
              Medycznej i Wyższej Szkoły Administracyjno-Społecznej w Warszawie.
              Ze stomatologią związana od 10 lat.{" "}
            </StyledH2>
            <StyledLink
              className="link"
              to="/about"
              title="Higienistka Magdalena Romatowska | O mnie"
            >
              Więcej
              <FiArrowRight
                className="link-icon"
                style={{
                  marginLeft: "1rem",

                  transform: "translateY(15%)",
                }}
              />
            </StyledLink>
          </StyledHeader>

          <StyledPhoto
            src={photo}
            data-aos="fade-left"
            data-aos-delay="1200"
            title="Higienistka Magdalena Romatowska"
            alt="Higienistka Magdalena Romatowska"
          ></StyledPhoto>
          {/* <StaticImage
            src="../assets/images/8.png"
            alt=""
            placeholder="tracedSVG"
            layout="fullWidth"
            style={{ width: "60vh" }}
            // imgStyle={}
            // height={900}
            objectFit={"cover"}

            // width={200}
          ></StaticImage> */}

          <CookieConsent
            debug={false}
            location="bottom"
            style={{
              background: "black",
              color: "#cbb26a",
              textAlign: "center",
              fontFamily: "Montserrat",
            }}
            buttonStyle={{
              background: "#cbb26a",
              color: "black",
              fontWeight: "600",
            }}
            buttonText="Akceptuję"
            expires={1}
          >
            Ta strona używa plików cookies (ciasteczka).
            <StyledCookieLink
              to="/cookies"
              title="Higienistka Magdalena Romatowska | Cookies"
            >
              Polityka prywatności
            </StyledCookieLink>
          </CookieConsent>
        </Wrapper>
      </Layout>
    </>
  )
}
